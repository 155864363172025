@media (max-width: 690px) {
  .items-container {
    row-gap: 15px;
  }
  .items-container {
    padding: 0 15px;
  }
  .htp__main-title {
    font-size: calc(21px + 11 * (100vw / 690));
  }
  .htp__text {
    font-size: 18px;
  }
  .htp__text1,
  .htp__text2,
  .htp__text3,
  .htp__text4,
  .htp__text5,
  .htp__text6 {
    font-size: 18px;
    margin-top: 8px;
  }
  .htp__text:last-child {
    margin-bottom: 40px;
  }
}

@media (max-width: 640px) {
  .htp {
    padding: 0 5px;
  }
}

@media (max-width: 480px) {
  .htp__title-block__name {
    font-size: calc(20px + 5 * (100vw / 380));
  }
}

@media (max-width: 425px) {
  .htp__title-block__name {
    font-size: calc(18px + 5 * (100vw / 324));
  }
}
@media (max-width: 375px) {
  .htp__title-block__name {
    font-size: calc(9px + 7 * (100vw / 324));
    margin-top: 0px;
  }
  .htp__main-title {
    font-size: calc(9px + 21 * (100vw / 585));
    margin-top: 4px;
  }
}

@media (max-width: 320px) {
  .htp__main-title {
    font-size: 20px;
    margin-top: 6px;
  }
}

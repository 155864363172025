.about-game {
  background: url(/public/bg-card.jpg);
  position: relative;
  z-index: 3;
  clip-path: polygon(
    0% 1.8%,
    5% 0%,
    95% 0%,
    100% 1.8%,
    100% 98.4%,
    95% 100%,
    5% 100%,
    0 98.4%
  );
}

.welcome-moon {
  display: flex;
  align-self: flex-end;
  width: 51%;
}

.welcome-moon__img {
  position: absolute;
  z-index: 5;
  left: 25px;
  top: -78px;
}

.welcome-moon__info {
  margin-top: 20px;
}

.info__greetings {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1;
}

.info__greetings-eng {
  text-transform: uppercase;
  font-size: 16px;
  color: #68378d;
}

.welcome-moon__icons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.welcome-moon__circulation-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.welcome-moon__circulation-info p {
  text-transform: uppercase;
  font-size: 24px;
}

.welcome-moon__circulation-info p span {
  color: #68378d;
}

.welcome-moon__delivery-date {
  text-transform: uppercase;
  font-size: 17px;
  color: #68378d;
}

.about-game iframe {
  width: 100%;
  height: 316px;
  border: 6px solid #68378d;
}

.about-game__text {
  line-height: 1.3;
  font-family: "Germano-Regular";
  font-size: 21px;
  text-align: justify;
}

.about-game__text-h4_1 {
  float: right;
  margin-top: 30px;
  font-family: "Germano-Italic";
}

.about-game__text-h4_1 p:last-child {
  text-align: right;
}

.about-game__text-h4_2 {
  margin-top: 10px;
}

.about-game__text:last-child {
  margin-bottom: 40px;
}

.item-card__container {
    padding-top: 150px;
}
a {
    text-decoration: none;
}
@media (max-width: 580px) {
    .item-card__container {
        padding-top: 80px;
    }
}
.items__order {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item__card__block {
    position: relative;
    width: 300px;
}
.items__card__layer {
    z-index: 1;
    background-image: url("/public/orderprod/layerprod.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    clip-path: polygon(
        6% 0%,
        94% 0%,
        100% 4%,
        100% 96%,
        94% 100%,
        6% 100%,
        0% 96%,
        0% 4%
    );
    height: 440px;
}

.item__card {
    display: flex;
    flex-direction: column;
    color: #ffbf3e;
    font-family: "Gun";
}
.item__card p {
    text-align: center;
}
.box1 {
    z-index: 2;
    position: absolute;
    width: calc(187 / 264 * 100%);
    left: 50%;
    transform: translateX(-50%) translateY(-75px);
}
.box2 {
    z-index: 2;
    position: absolute;
    width: calc(229 / 264 * 100%);
    margin-left: auto;
    left: 0;
    right: 0;
    margin-right: auto;
    transform: translateY(-38px);
}
.item__sumcontainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    margin-left: auto;
    left: 0;
    right: 0;
    margin-right: auto;
}
.item__title {
    font-family: "Germano-Bold";
    font-size: 24px;
    color: white;
    margin-top: 140px;
}
.item__title span {
    font-family: "Germano-Regular";
}
.item__subsum {
    transform: translateY(12%);
    position: relative;
    padding-top: 10px;
    font-size: 30px;
}
.item__subsum p::before {
    content: "";
    width: 48%;
    position: absolute;
    border-bottom: 3px solid;
    margin-top: 7.5%;
    transform: skewY(7deg);
}
.subsum__hide p {
    display: none;
    padding-bottom: 8px;
}
.item__sum {
    margin-bottom: -14px;
    font-size: 44px;
}
.item__button {
    cursor: pointer;
    font-size: 30px;
    color: #ffbf3e;
    margin: 0 auto;
    text-transform: uppercase;
    border: none;
    background: no-repeat url("/public/orderprod/buttonborder.png");
    background-size: 100% 100%;
    background-position: center;
    padding: 25px 45px;
}
button {
    top: 0;
    left: 0;
}
.order__about__container {
    margin-top: 53px;
    max-width: 540px;
}
ul {
    padding: 0;
    margin: 0;
}
li {
    padding-left: 4px;
}
li::marker {
    content: "►";
}
.order__text {
    color: white;
    font-family: "Germano-Regular";
    font-size: 21px;
}
.order__text:not(:last-child) {
    padding-bottom: 21px;
}
.order__text a:active,
.order__text a:hover,
.order__text a {
    font-family: "Germano-Bold";
    text-decoration: none;
    color: white;
}
.order__text span {
    padding-right: 6px;
}
.order__under__img {
    padding-bottom: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: calc(231 / 530 * 100%);
}
@media (max-width: 580px) {
    .order__text__container {
        padding: 0 15px;
    }
    .order__about__container {
        padding: 0 15px;
    }
    .order__text {
        font-size: calc(0px + 21 * (100vw / 530));
    }
    .item__title p {
        width: 100%;
    }
    .item__card p {
        margin: -2%;
    }
    .items__order {
        width: 50%;
    }
    .box1 {
        transform: translateX(-50%) translateY(-39%);
    }
    .box2 {
        width: calc(220 / 279 * 100%);
        transform: translateY(-26%);
    }
    .items__card__layer {
        width: 50vw;
        height: 70vw;
    }
    .item__title {
        font-size: calc(2px + 21 * (100vw / 580));
        margin-top: 47%;
    }
    .item__subsum {
        font-size: calc(2px + 28 * (100vw / 580));
        padding-top: 0;
        transform: translateY(4%);
    }
    .item__sum {
        font-size: calc(2px + 42 * (100vw / 580));
        margin-bottom: -6%;
    }
    .item__button {
        font-size: calc(2px + 28 * (100vw / 580));
        padding: 8% 18%;
    }
    .item__subsum p::before {
        width: 45.5%;
        border-bottom: 2px solid;
        margin-top: 6.5%;
        transform: skewY(6deg);
    }
}
@media (max-width: 476px) {
    .order__text br {
        content: "";
    }
}

.composition__ {
  padding: 24px 0 50px 0;
  /* margin: 0 15px; */
  background: url(/public/bg-card.jpg);
  clip-path: polygon(
    0px 60px,
    30px 35px,
    25% 35px,
    30% 0px,
    70% 0%,
    75% 35px,
    calc(100% - 30px) 35px,
    100% 60px,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
}
.composition__title {
  padding: 4px 0 0 0;
  display: flex;
  justify-content: center;
  font-size: 36px;
  transform: translateY(-30px);
  color: #b65128;
}
.composition__container {
  padding-bottom: 120px;
}
@media (max-width: 585px) {
  .composition__title {
    font-size: calc(9px + 21 * (100vw / 585));
    margin-top: 2px;
  }
}
@media (max-width: 425px) {
  .composition__title {
    font-size: calc(9px + 21 * (100vw / 585));
  }
  .composit__image {
    transform: translateY(-18px);
  }
}

@media (max-width: 320px) {
  .composition__title {
    font-size: 20px;
    margin-top: 6px;
  }
}

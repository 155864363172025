.footer {
    background-color: #17131f;
}
.footer-container {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footer__image {
    display: flex;
    gap: 54px;
    align-items: center;
    justify-content: center;
}
.image__item {
    width: 36px;
}
.footer__about {
    color: #f8e2bb;
    text-align: center;
    max-width: 532px;
    margin: 0 auto;
    padding-top: 27px;
    display: flex;
    flex-direction: column;
    font-family: "Germano-Bold";
    font-size: 20px;
}
@media (max-width: 620px) {
    .footer__about {
        font-size: calc(4px + 14 * (100vw / 620));
    }
}
@media (max-width: 425px) {
    .footer__image {
        gap: 30px;
    }
}

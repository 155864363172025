.htp {
  background: url(/public/bg-card.jpg);
  clip-path: polygon(
    0px 60px,
    30px 35px,
    25% 35px,
    30% 0px,
    70% 0%,
    75% 35px,
    calc(100% - 30px) 35px,
    100% 60px,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
}

.items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.htp__main-title {
  text-transform: uppercase;
  color: #b65128;
  font-size: 36px;
}

.htp__title-block {
  display: flex;
  flex-direction: column;
  position: relative;
  text-transform: uppercase;
  color: #f5dfb8;
  font-size: 30px;
  align-items: center;
  justify-content: center;
}

.htp__title-block__name {
  position: absolute;
  margin-top: 2px;
}

.htp__text {
  line-height: 1.3;
  font-family: "Germano-Regular";
  font-size: 21px;
  text-align: justify;
}

.htp__text:last-child {
  margin-bottom: 80px;
}

.htp__text1,
.htp__text2,
.htp__text3,
.htp__text4,
.htp__text5,
.htp__text6 {
  margin-top: 10px;
}

@media (max-width: 690px) {
  .reviews__title {
    font-size: calc(21px + 11 * (100vw / 690));
  }
}

@media (max-width: 605px) {
  .reviews {
    padding: 0 0 60px 0;
  }
  .reviews iframe {
    width: 85vw;
    height: 48.65vw;
  }
}

@media (max-width: 375px) {
  .htp__main-title {
    font-size: 24px;
  }
  .reviews__title {
    font-size: calc(9px + 21 * (100vw / 585));
    margin-top: 4px;
  }
}

@media (max-width: 320px) {
  .reviews__subtitle {
    font-size: 18px;
    margin-top: 18px;
  }
  .reviews {
    padding: 0 0 20px 0;
  }
  .reviews__title {
    margin-top: 6px;
    font-size: 20px;
  }
}

.header {
  color: #fbf3cf;
  font-size: 18px;
  position: relative;
  margin-bottom: -100px;
}
@media (max-width: 690px) {
  .header {
    margin-bottom: 0;
  }
}
.header__background {
  background-image: url("/public/header/header.webp");
  background-position: 0px -120px;
  height: 592px;
}
.header__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header__welcome {
  margin-top: 260px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header__welcome p {
  line-height: 34px;
  font-size: 28px;
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.header__welcome img {
  position: relative;
  padding-top: 0;
  transform: translateY(5px);
  width: 256px;
  height: 54px;
}
.header__left__img {
  padding-left: 18px;
}
.header__right__img {
  padding-right: 18px;
}
.header__nav {
  overflow: hidden;
  margin-top: 40px;
  font-size: 18px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  padding: 0 5px;
}
.header__nav-fixed {
  position: fixed;
  z-index: 999;
  padding: 10px 5px;
  height: 40px;
  margin: 0 auto;
  max-width: 640px !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #120f1b;
}
.header__nav__item {
  align-self: center;
  line-height: 18px;
  transform: skewX(-20deg);
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  display: flex;
  justify-content: center;
}
.header__nav__item a {
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transform: skewX(20deg);
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  color: #fbf3cf;
}
.header__nav__item-active {
  text-align: center;
  background-color: rgba(251, 243, 207, 0.4);
}
.header__nav__item:not(:last-child) {
  border-right: 5px solid #fbf3cf;
}
.nav__active::after {
  content: ".";
  background-color: rgba(255, 255, 255, 0.2);
  background-size: 250% 100%;
}
@media (max-width: 690px) {
  .header {
    margin-bottom: 0;
  }
  .header__nav__item {
    line-height: calc(3px + 12 * (100vw / 690));
  }
}
@media (max-width: 640px) {
  .header__nav__item:not(:last-child) {
    border-right: 4px solid;
  }
  .header__nav__item {
    line-height: calc(3px + 12 * (100vw / 690));
  }
  .header__background {
    background-position: 0px 70%;
    background-size: 100%;
    height: 80vw;
  }
  .header__nav__item {
    font-size: calc(3px + 12 * (100vw / 640));
  }
  .header__nav__item:not(:last-child)::after {
    margin-left: 5%;
    background: #fbf3cf;
    border: 1px solid;
    height: 14px;
  }
  .header__welcome {
    margin-top: 40%;
  }
  .header__welcome p {
    line-height: 118%;
    font-size: calc(10px + 18 * (100vw / 640));
  }
  .header__welcome img {
    width: 38%;
    height: 125%;
  }
}
@media (max-width: 425px) {
  .header__nav__item:not(:last-child) {
    border-right: 3px solid;
  }
  .header__nav__item {
    line-height: calc(3px + 12 * (100vw / 690));
  }
  .header__nav__item {
    font-size: calc(2px + 10 * (100vw / 425));
  }
  .header__nav__item:not(:last-child)::after {
    transform: translateY(2px);
    margin-left: 4.5%;
    background: #fbf3cf;
    border: 1px solid;
    height: 11px;
  }
  .header__welcome p {
    line-height: 120%;
    font-size: calc(1px + 18 * (100vw / 420));
  }
}
@media (max-width: 375px) {
  .header__nav__item:not(:last-child)::after {
    margin-left: 4%;
  }
  .header__nav__item:not(:last-child) {
    border-right: 2px solid;
  }
  .header__nav__item {
    line-height: calc(3px + 12 * (100vw / 690));
  }
  .header__nav__item {
    font-size: calc(2px + 10 * (100vw / 425));
  }
}

.reviews {
  padding: 0 40px 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(/public/bg-card.jpg);
  clip-path: polygon(
    0px 60px,
    30px 35px,
    32% 35px,
    36% 0px,
    64% 0%,
    68% 35px,
    calc(100% - 30px) 35px,
    100% 60px,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
}

.rew-container {
  max-width: 640px;
  margin: 0 auto;
  padding: 0 15px;
  background-color: rgba(0, 0, 0, 0.7);
}

.reviews__title {
  font-family: "Gun";
  font-size: 36px;
  color: #68378d;
  text-transform: uppercase;
}

.reviews__subtitle {
  font-family: "Germano-Regular";
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
}

.reviews iframe {
  margin-top: 10px;
  width: 100%;
  height: 316px;
  border: 6px solid #68378d;
}

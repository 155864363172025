@media (max-width: 690px) {
  .welcome-moon {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 230px;
  }
  .about-game__text {
    font-size: 18px;
  }
  .welcome-moon__delivery-date {
    font-size: 18px;
  }
  .welcome-moon__img {
    width: 260px;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
  }
  .welcome-moon__img img {
    width: 100%;
  }
  .info__greetings {
    font-size: 36px;
  }
  .info__greetings-eng {
    font-size: 18px;
  }
  .welcome-moon__delivery-date {
    text-align: center;
  }
  .about-game {
    clip-path: polygon(
      0% 1.3%,
      5% 0%,
      95% 0%,
      100% 1.3%,
      100% 98.4%,
      95% 100%,
      5% 100%,
      0 98.4%
    );
  }
}

@media (max-width: 605px) {
  .about-game iframe {
    width: 85vw;
    height: 48.65vw;
  }
}

@media (max-width: 545px) {
  .info__greetings {
    font-size: 30px;
  }
  .info__greetings-eng {
    font-size: 16px;
  }
}
@media (max-width: 420px) {
  .welcome-moon__circulation-info {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  .welcome-moon__circulation-info p {
    font-size: 22px;
    margin-top: 5px;
  }
  .welcome-moon__img {
    width: 220px;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
  }
}

@media (max-width: 342px) {
  .info__greetings {
    font-size: 27px;
  }
  .welcome-moon__img {
    top: -40px;
  }
}

@media (max-width: 464px) {
  .info__greetings,
  .info__greetings-eng {
    text-align: center;
  }
}

.unfolding-video {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 0;
    overflow: hidden;
}

.unfolding-video iframe {
    width: 100%;
}

.unfolding-video-open {
    height: auto;
}

.unfolding-video__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 605px) {
    .unfolding-video iframe {
        width: 85vw;
        height: 48.65vw;
    }
}
